.footer {
  background-color: #2e3032;
  height: 86px;
  display: flex;
  place-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

.footer a {
  color: #ffff;
  text-decoration: none;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
}

.footer a:hover {
  color: #1db1bf;
}

@media screen and (min-width: 1050px) {
  .footer {
    height: 49px;
  }
}
