.appSection {
  display: grid;
  place-content: center;
  margin-top: 150px;
  background-color: #1bd1bf;
  padding: 20px 20px 0 20px;
  gap: 70px;
}

.upperContainer {
  display: flex;
  flex-direction: column;
  padding: 30px 40px 50px;
  gap: 50px;
}

.text {
  text-align: center;
  float: left;
  color: white;
  max-width: 340px;
  width: 100%;
  font-size: 20px;
}

.downloadApp {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.image {
  width: 100%;
}

.featureImage {
  margin: 0 auto -5px;
}

.featureImage img {
  width: 341px;
  height: auto;
}

.linkButton {
  display: block;
  position: relative;
}

/* .linkButton:nth-child(2)::before {
  content: "Coming soon";
  color: white;
  font-weight: bold;
  font-size: 20px;
  display: grid;
  place-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111111;
  opacity: 0.9;
  border-radius: 8px;
} */

.qrCode {
  display: none;
}

@media screen and (min-width: 1050px) {
  .appSection {
    grid-template-columns: auto auto;
    gap: 100px;
  }

  .upperContainer {
    gap: 20px;
  }

  .downloadApp {
    flex-direction: row;
    align-content: space-evenly;
  }

  .text {
    text-align: left;
  }

  .image {
    width: 80%;
  }

  .qrCode {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  .linkButton {
    display: none;
  }

  /* .qrCode:last-child::after {
    content: "Coming soon";
    color: white;
    font-weight: bold;
    font-size: 20px;
    display: grid;
    place-content: center;
    position: absolute;
    width: 80%;
    height: 100%;
    background: #111111;
    opacity: 0.9;
    border-radius: 8px;
  } */
}
