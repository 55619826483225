.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* align-items: center; */
  /* text-align: center; */
}
.acordionContainer {
  margin: 20px;
}
.headerContainer {
  display: flex;
  background-color: #1ad1bf;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.header {
  padding: 20px 5px 20px 5px;
  color: rgba(255, 255, 255, 255);
  font-size: 37px;
  align-self: center;
}
@media (max-width: 425px) {
  .header {
    font-size: 30px;
  }
}
.textContainer {
  padding-left: 50px;
  padding-right: 50px;
}
.text {
  font-weight: 10px;
  font-size: 16px;
  color: #161515;
  line-height: 1.5em;
  margin: 15px;

  display: block;
}
.bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: rgba(26, 209, 191, 255);
}

.bottomLink {
  color: #ffff;
  text-decoration: none;
}
.bottomLink:hover {
  color: #6c6a6a;
}
.title {
  font-weight: bold;
}
.text {
  padding-left: 20px;
}
.bullet {
}
