.container {
  width: 100%;
  background-color: rgba(26, 209, 191, 255);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.logo {
  width: 100%;
  height: 100px;
  background-color: rgba(255, 255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
}
.header {
  margin-top: 70px;
  margin-bottom: 20px;
  padding: 0 5px 0 5px;
  max-width: 500px;
  color: rgba(255, 255, 255, 255);
  font-size: 70px;
}
.scrollToDownload {
  display: grid;
  place-content: center;
  background-color: black;
  max-width: 305px;
  width: 100%;
  height: 55px;
  border-radius: 8px;
  margin-top: 60px;
  text-decoration: none;
  color: white;
}

.scrollToDownload:hover {
  background: #1b1a1a;
  color: white;
}

.scrollToDownload a:visited,
.scrollToDownload a:active {
  background-color: black;
  color: white;
}

@media (max-width: 425px) {
  .header {
    font-size: 30px;
  }
}

.imageContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 25px;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(26, 209, 191, 255) 50%,
    white 50%
  );
}
@media (max-width: 425px) {
  .imageContainer {
    margin-top: 30px;
  }
}

.imageContainer div {
  margin: 25px;
}

@media (min-width: 769px) {
  .imageContainer .leftImg,
  .rightImg {
    width: 20%;
  }

  .imageContainer .mainImg {
    width: 25%;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .imageContainer .rightImg,
  .mainImg {
    width: 32%;
  }

  .imageContainer .leftImg {
    display: none;
  }
}

@media (max-width: 426px) and (max-width: 768px) {
  .imageContainer .leftImg,
  .rightImg {
    display: none;
  }
}

.imageContainer img {
  max-width: 100%;
  height: auto;
}

.bannerLeftImg {
  border-radius: 20px;
  box-shadow: 2px 0px 8px 0px rgb(138 138 138 / 33%);
}

.bannerMainImg {
  border-radius: 20px;
  /* box-shadow: 0px 2px 7px 2px rgb(138 138 138 / 25%); */
}

.bannerRightImg {
  border-radius: 20px;
  box-shadow: 1px 0px 10px 0px rgb(138 138 138 / 49%);
}
