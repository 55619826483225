.container {
  width: 100%;
  background-color: rgba(255, 255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.img {
  display: none;
}

.texContainer {
  text-align: left;
  max-width: 600px;
  margin: 50px 30px;
}
.header {
  font-size: 48px;
  font-weight: 600;
  color: #161515;
  line-height: 1.5em;
  margin-bottom: 20px;
}
.text {
  font-weight: 10px;
  font-size: 16px;
  color: #161515;
  line-height: 1.5em;
  margin-top: 15px;
  display: block;
}

@media screen and (min-width: 768px) {
  .container {
    padding-top: 60px;
  }

  .imgContainer {
    display: block;
    background: linear-gradient(to top, rgba(26, 209, 191, 255) 50%, white 50%);
    max-width: 479px;
    padding-left: 70px;
    padding-right: 70px;
    object-fit: contain;
    margin-left: 30px;
    padding-bottom: 20px;
  }
  .img {
    width: 100%;
    display: block;
    box-shadow: 2px 0px 8px 0px rgb(138 138 138 / 33%);
    border-radius: 10px;
  }

  .texContainer {
    margin-left: 56px;
  }
}
@media screen and (min-width: 990px) {
  .img {
    border-radius: 20px;
  }
}
